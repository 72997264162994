<style lang="scss">
.spezifikationen {
  h1 {
    margin-bottom: 40px !important;
  }
  h2 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .adressen-box {
    margin-top: 20px;
    margin-bottom: 20px;
    h2 {
      margin-bottom: 15px;
      margin-top: 0;
      text-transform: uppercase;
    }
    h3 {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 150%;
      &:after {
        display: none !important;
      }
    }
    p {
      margin-bottom: 5px;
      a {
        &:hover {
          color: $primary-red;
        }
      }
    }
  }
  .std-content {
    margin-top: 40px;
    h4 {
      margin-top: 30px;
    }
    p {
      padding-left: 10px;
      border-left: 2px solid #eee;
      a {
        &:hover {
          color: $primary-red;
        }
      }
    }
  }
  .achtschritte {
    margin-top: 30px;
    margin-bottom: 30px;
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
    .col-lg-7 {
      border-left: 1px solid #ccc;
      padding-left: 25px;
      p {
        margin-bottom: 0;
      }
      ul {
        margin-top: 10px;
      }
      h4 {
        text-transform: uppercase;
      }
    }

    img {
      display: block;
      margin: 20px auto;
      max-width: 200px;
      @media (max-width: 992px) {
        margin: 40px 0 30px 0;
        max-width: 250px;
      }
    }
  }
  .tabelle-box {
    .head-row {
      @media (max-width: 992px) {
        display: none;
      }
    }
    .visible-small {
      display: none;
      @media (max-width: 992px) {
        display: block;
      }
    }
    .text-align-left {
      @media (max-width: 992px) {
        text-align: right !important;
      }
    }
    .col-6 {
      justify-items: center;
      align-self: center;
    }
  }
  .tabelle-box-v2 {
    .text-align-left {
      @media (max-width: 992px) {
        text-align: left !important;
      }
    }
  }
  .kategorie-box {
    margin-bottom: 20px;

    .col-md-4 {
      text-align: right;
      @media (max-width: 768px) {
        text-align: left;
      }
      span {
        display: block;
        font-size: 120%;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
}
</style>

<template>
  <div class="spezifikationen content container">
    <h1>Spezifikationen Beilagen und Sonderwerbeformen</h1>

    <div class="row adressen-box">
      <div class="col-lg-12">
        <h2>Steiermark</h2>
      </div>
      <div class="col-lg-6">
        <h3>Lieferadresse Steiermark Beilagen</h3>
        <p>Druck Styria GmbH &amp; Co KG</p>
        <p>EXPEDIT, Styriastraße 20, 8042 Graz</p>
        <p>Tel. <a href="tel:+43316809920">+43 (0) 316 8099 20</a></p>
        <p>Anlieferungszeiten: Mo-Fr, 07:00 bis 14 Uhr</p>
      </div>
      <div class="col-lg-6">
        <h3>Lieferadresse Steiermark zu Zusatzhaushalte</h3>
        <p>Redmail Expedit</p>
        <p>TLC Halle, Styriastraße 31, Tor 1, 8042 Graz</p>
        <p>Tel. <a href="tel:+4367683914505">+43 (0) 676 8391 4505</a></p>
        <p>Anlieferungszeiten: Mo-Do, 10:00 bis 16:00 Uhr // Fr, 07:00 bis 14:00 Uhr</p>
      </div>
    </div>
    <div class="row adressen-box">
      <div class="col-lg-12">
        <h2>Kärnten</h2>
      </div>
      <div class="col-lg-6">
        <h3>Lieferadresse Kärnten Beilagen</h3>
        <p>Druck Carinthia GmbH &amp; Co KG</p>
        <p>Expedit, Industrieparkstraße 6, 9300 St. Veit/Glan</p>
        <p>Tel. <a href="tel:+43421228500300">+43 (0) 4212 28500 300</a></p>
        <p>Anlieferungszeiten: Mo-Do, 07:00 bis 14:30 Uhr // Fr, 08:00 bis 12:00 Uhr</p>
      </div>
      <div class="col-lg-6">
        <h3>Lieferadresse Kärnten zu Zusatzhaushalte</h3>
        <p>Europaket</p>
        <p>Wirtschaftpark 36, 9130 Poggersdorf</p>
        <p>Tel. <a href="tel:+43421228882">+43 (0) 4212 28882</a></p>
        <p>Anlieferungszeiten: Mo-Do, 08:00 bis 16:00 Uhr // Fr, 08:00 bis 12:00 Uhr</p>
      </div>
    </div>
    <div class="std-content">
      <h4>Anlieferungszeit</h4>
      <p>Drei Werktage vor dem Verarbeitungstermin (beachten Sie bitte die Lieferadressen und Anlieferungszeiten).</p>
      <p>Für später angelieferte Beilagen/Sonderwerbeformen kann keine Ga­rantie für eine Qualitätskontrolle und somit für eine ordnungsgemäße Verarbeitung übernommen werden .</p>
      <h4>Anlieferungszustand</h4>
      <p>Die Beilagen/Sonderwerbeformen müssen unversehrt in der Druckerei ankommen, daher muss die Palette mit einer Folie und Kantenschutz ausgestattet sein.</p>
      <p>Die angelieferten Beilagen/Sonderwerbeformen müssen in Art und Form eine einwandfreie, sofortige maschinelle Verarbeitung gewähr­leisten, ohne dass eine zusätzliche manuelle Aufbereitung notwendig ist. Eine zusätzliche manuelle Aufbereitung-wenn diese überhaupt möglich ist-wird gesondert in Rechnung gestellt.</p>
      <p>Durch zu frische Druckfarbe zusammengeklebte oder feucht geworde­ne Beilagen/Sonderwerbeformen können nicht verarbeitet werden.</p>
      <p>Beilagen/Sonderwerbeformen mit umgeknickten Ecken bzw. Kanten (Eselsohren), Quetschfalten oder mit verlagertem (rundem) Rücken sind ebenfalls nicht verarbeitbar.</p>
      <p>Elektrostatisch aufgeladene oder durch stumpfe Schneidmesser verblockte Beilagen/Sonderwerbeformen verursachen Produktions­störungen. Es kann zu Mehrfachbeilagen in einer Zeitung kommen. Im schlimmsten Fall kann es zu einem Produktionsausfall kommen.</p>
      <h4>Technische Bedingungen für eine sofortige maschinelle Verarbeitung</h4>
      <p>Siehe „Beschaffenheit der Beilagen" bzw . .,Beschaffenheit der Son­derwerbeformen".</p>
      <p>Wenn Beilagen/Sonderwerbeformen weit von der Beschaffenheit abweichen, ist eventuell ein händisches Belegen notwendig, das zu deutlichen Mehrkosten führt.</p>
      <h4>Fremdinserate</h4>
      <p>
        Inserate (auch in Beilagen/Sonderwerbeformen) dürfen lediglich Eigenwerbezwecken dienen. Eine Weitergabe an Dritte, sohin Fremd­inserate, bedarf der ausdrücklichen vorherigen schriftlichen Zustim­mung des Auftragnehmers. Bei Zuwiderhandeln hat der Auftraggeber als verschuldensunabhängige Pönale den zweifachen Tarifwert der Buchung bei jedem Verstoß unverzüglich zu bezahlen. Weitergehende
        Ansprüche des Auftragnehmers (z. B. Schadenersatz) bleiben davon unberührt.
      </p>

      <h4>Stornierung</h4>
      <p>Ausfallkosten bei Stornierung eines verbindlichen Buchungsauftrages werden auf Basis des Beilagenpreises der niedrigsten Gewichtsklasse berechnet:</p>
      <ul class="content-ul">
        <li>länger als 1 Monat vor Erscheinungstermin: keine Stornierungskosten</li>
        <li>9 Tage bis 1 Monat vor Erscheinungstermin: 20 % des Beilagenpreises</li>
        <li>bis 8 Tage vor Erscheinungstermin: 50 % des Beilagenpreises</li>
      </ul>
    </div>
    <h2>In acht Schritten zur ordnungsgemäßen<br />Anlieferung und Verpackung Ihrer Paletten</h2>
    <div class="row achtschritte">
      <div class="col-lg-5">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/01-paletten.png" alt="1 Paletten" class="img-fluid" />
      </div>
      <div class="col-lg-7">
        <h4>1. Paletten</h4>
        <ul class="content-ul">
          <li>EURO-Paletten</li>
          <li>Standardmaß: 120 x 80 x 14,4 cm (L x b x h)</li>
        </ul>
      </div>
    </div>
    <div class="row achtschritte">
      <div class="col-lg-5">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/02-lagen.png" alt="2 Lagen" class="img-fluid" />
      </div>
      <div class="col-lg-7">
        <h4>2. Lagen</h4>
        <ul class="content-ul">
          <li>Die unverschränkten, kantengeraden Lagen sollen eine Höhe von 10 cm aufweisen. In geringer Stückzahl verschränkte Beilagen/ Sonderwerbeformen müssen in einem separaten Vorgang entschränkt und die dadurch anfallenden Kosten weiterverrech­net werden.</li>
          <li>Einzelne Pakete müssen mit der Rückseite nach oben liegen und dürfen nicht gebündelt oder verpackt sein.</li>
          <li>Eine Vorsortierung wegen zu kleiner Lagen führt zu Mehrkosten, welche an den Auftrag­geber verrechnet werden.</li>
        </ul>
      </div>
    </div>
    <div class="row achtschritte">
      <div class="col-lg-5">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/03-stapelung-der-paletten.png" alt="3 Steplung der Paletten" class="img-fluid" />
      </div>
      <div class="col-lg-7">
        <h4>3. Stapelung der Paletten</h4>
        <ul class="content-ul">
          <li>Um Instabilität und Schmutz vorzubeugen, müssen unten, zwischen und auf den einzel­nen Lagen große Kartons liegen.</li>
        </ul>
      </div>
    </div>
    <div class="row achtschritte">
      <div class="col-lg-5">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/04-gewicht-der-paletten.png" alt="4 Gewicht der Paletten" class="img-fluid" />
      </div>
      <div class="col-lg-7">
        <h4>4. Gewicht der Paletten</h4>
        <ul class="content-ul">
          <li>max. 700 kg pro Palette</li>
        </ul>
      </div>
    </div>
    <div class="row achtschritte">
      <div class="col-lg-5">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/05-hoehe-der-paletten.png" alt="5 Höhe der Paletten" class="img-fluid" />
      </div>
      <div class="col-lg-7">
        <h4>5. Höhe der Paletten</h4>
        <ul class="content-ul">
          <li>Die Höhe der Paletten darf 120 cm (inkl. der Palette) nicht übersteigen, da es sonst zu Transportproblemen kommen kann. Eine Nichteinhaltung der Maximalhöhe führt zu einer manuellen Palettenmanipulation. Der zusätzliche Aufwand wird gesondert in Rechnung gestellt.</li>
        </ul>
      </div>
    </div>
    <div class="row achtschritte">
      <div class="col-lg-5">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/06-begleitpapiere.png" alt="6 Begleitpapiere" class="img-fluid" />
      </div>
      <div class="col-lg-7">
        <h4>6. Begleitpapiere</h4>
        <p>Die Lieferung von Beilagen/Sonderwerbe­formen muss mit korrektem Lieferschein erfolgen.</p>
        <p><strong>Folgende Angaben müssen auf dem Liefer­schein enthalten sein: </strong></p>
        <ul class="content-ul">
          <li>Name des zu belegenden Zeitungsmediums</li>
          <li>Erscheinungsdatum</li>
          <li>Kunde/Auftraggeber</li>
          <li>Beilagentitel oder Motiv</li>
          <li>Auslieferungstermin</li>
          <li>Absender</li>
          <li>Anzahl der Paletten</li>
          <li>Gesamtstückzahl der gelieferten Beilagen/ Sonderwerbeformen und Gesamtgewicht der Lieferung.</li>
        </ul>
        <p>Jede Palette muss analog zum Lieferschein deutlich und sichtbar mit einem <strong>Palettenzettel</strong> gekennzeichnet sein.</p>
      </div>
    </div>
    <div class="row achtschritte">
      <div class="col-lg-5">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/07-getrennte-anlieferung.png" alt="7 Getrennte Anlieferung bei Kombiaufträgen" class="img-fluid" />
      </div>
      <div class="col-lg-7">
        <h4>7. Getrennte Anlieferung bei Kombiaufträgen</h4>
        <p>Bei sogenannten Kombiaufträgen (STEIERMARK und KÄRNTEN, KLEINE ZEITUNG ABOHAUSHALTE und ZUSATZ HAUSHALTE) muss die Anlieferung der erforderlichen Stückzahlen nach den jewei­ligen Aufträgen getrennt erfolgen (beachten Sie bitte die Lieferadressen).</p>
      </div>
    </div>
    <div class="row achtschritte">
      <div class="col-lg-5">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/08-lieferung-frei-haus.png" alt="8 Lieferung frei Haus" class="img-fluid" />
      </div>
      <div class="col-lg-7">
        <h4>8. Lieferkonditionen</h4>
        <p>Die Waren sind ausnahmslos verzollt, versteu­ert und frei Haus zu liefern (unfrei übersandte Waren werden vom Expedit nicht angenom­men). Als ordnungsgemäß übernommene Lieferungen werden nur jene Anlieferungen bezeichnet, auf deren Lieferschein sich der Warenannahmestempel und die Unterschrift der Warenannahme befinden.</p>
      </div>
    </div>
    <h2>VERARBEITUNG</h2>
    <div class="std-content">
      <h4>Maschinelle Beilagen</h4>
      <ul class="content-ul">
        <li>Diese können nur im Bund beigelegt werden.</li>
        <li>Die Reihenfolge, wie die Beilagen in der Zeitung liegen, kann nicht im Voraus bestimmt werden.</li>
        <li>Die Reihenfolge ist abhängig von der Beschaffenheit und Anzahl der Beilagen und kann daher erst in der Verarbeitung festgelegt werden.</li>
      </ul>
      <h4>Mindestauflage</h4>
      <ul class="content-ul">
        <li>Beilagenaufträge sind erst ab 5.000 Stück buchbar.</li>
      </ul>
      <h4>Restexemplare</h4>
      <ul class="content-ul">
        <li>Ein etwaiger Rest wird nach drei Arbeitstagen automatisch entsorgt.</li>
      </ul>
      <h4>Zuschuss</h4>
      <ul class="content-ul">
        <li>Generell+ 1,5 %</li>
        <li>Bei Kleinaufträgen bis 10.000 Stk. sind 300 Stk. Zuschuss für den Probelauf erforderlich.</li>
      </ul>
      <h4>Verarbeitungsgarantie</h4>
      <ul class="content-ul">
        <li>Wichtig: Für Werbemittel, welche nicht der Spezifikation ent­sprechen, kann keine Verarbeitungsgarantie gewährt werden.</li>
      </ul>
      <div class="tabelle-box">
        <div class="row head-row">
          <div class="col-md-2 text-align-left">Auflage</div>
          <div class="col-md-3 text-align-left">Stück</div>
          <div class="col-md-4 text-align-left">Verarbeitung</div>
          <div class="col-md-1 text-align-right">A</div>
          <div class="col-md-1 text-align-right">B</div>
          <div class="col-md-1 text-align-right">C</div>
        </div>
        <div class="row content-row">
          <div class="col-6 visible-small">Auflage</div>
          <div class="col-md-2 col-6 text-align-left">Teilauflage</div>
          <div class="col-6 visible-small">Stück</div>
          <div class="col-md-3 col-6 text-align-left">bis 15.000</div>
          <div class="col-6 visible-small">Verarbeitung</div>
          <div class="col-md-4 col-6 text-align-left">selektive Verarbeitung, maschinell</div>
          <div class="col-6 visible-small">A</div>
          <div class="col-md-1 col-6 text-align-right">93%</div>
          <div class="col-6 visible-small">B</div>
          <div class="col-md-1 col-6 text-align-right">90%</div>
          <div class="col-6 visible-small">C</div>
          <div class="col-md-1 col-6 text-align-right">90%</div>
        </div>
        <div class="row content-row">
          <div class="col-6 visible-small">Auflage</div>
          <div class="col-md-2 col-6 text-align-left">Teilauflage</div>
          <div class="col-6 visible-small">Stück</div>
          <div class="col-md-3 col-6 text-align-left">bis 15.000</div>
          <div class="col-6 visible-small">Verarbeitung</div>
          <div class="col-md-4 col-6 text-align-left">Verarbeitung in einem Block, maschinell</div>
          <div class="col-6 visible-small">A</div>
          <div class="col-md-1 col-6 text-align-right">94%</div>
          <div class="col-6 visible-small">B</div>
          <div class="col-md-1 col-6 text-align-right">92%</div>
          <div class="col-6 visible-small">C</div>
          <div class="col-md-1 col-6 text-align-right">90%</div>
        </div>
        <div class="row content-row">
          <div class="col-6 visible-small">Auflage</div>
          <div class="col-md-2 col-6 text-align-left">Teilauflage</div>
          <div class="col-6 visible-small">Stück</div>
          <div class="col-md-3 col-6 text-align-left">bis 500.000</div>
          <div class="col-6 visible-small">Verarbeitung</div>
          <div class="col-md-4 col-6 text-align-left">maschinell</div>
          <div class="col-6 visible-small">A</div>
          <div class="col-md-1 col-6 text-align-right">96%</div>
          <div class="col-6 visible-small">B</div>
          <div class="col-md-1 col-6 text-align-right">93%</div>
          <div class="col-6 visible-small">C</div>
          <div class="col-md-1 col-6 text-align-right">92%</div>
        </div>
        <div class="row content-row">
          <div class="col-6 visible-small">Auflage</div>
          <div class="col-md-2 col-6 text-align-left">Teilauflage</div>
          <div class="col-6 visible-small">Stück</div>
          <div class="col-md-3 col-6 text-align-left">über 500.000</div>
          <div class="col-6 visible-small">Verarbeitung</div>
          <div class="col-md-4 col-6 text-align-left">maschinell</div>
          <div class="col-6 visible-small">A</div>
          <div class="col-md-1 col-6 text-align-right">98%</div>
          <div class="col-6 visible-small">B</div>
          <div class="col-md-1 col-6 text-align-right">95%</div>
          <div class="col-6 visible-small">C</div>
          <div class="col-md-1 col-6 text-align-right">95%</div>
        </div>
        <div class="row content-row">
          <div class="col-6 visible-small">Auflage</div>
          <div class="col-md-2 col-6 text-align-left">Gesamtauflage</div>
          <div class="col-6 visible-small">Stück</div>
          <div class="col-md-3 col-6 text-align-left"></div>
          <div class="col-6 visible-small">Verarbeitung</div>
          <div class="col-md-4 col-6 text-align-left">maschinell</div>
          <div class="col-6 visible-small">A</div>
          <div class="col-md-1 col-6 text-align-right">99%</div>
          <div class="col-6 visible-small">B</div>
          <div class="col-md-1 col-6 text-align-right">97%</div>
          <div class="col-6 visible-small">C</div>
          <div class="col-md-1 col-6 text-align-right">96%</div>
        </div>
      </div>
      <div class="row kategorie-box">
        <div class="col-lg-3 col-md-4">
          <span>Kategorie A</span>
        </div>
        <div class="col-lg-9 col-md-8"><strong>Format:</strong> Zeitungsformat, mittlere Papierqualität, ohne Flappe, Tip-on-Card oder Warenproben.</div>
      </div>
      <div class="row kategorie-box">
        <div class="col-lg-3 col-md-4">
          <span>Kategorie B</span>
        </div>
        <div class="col-lg-9 col-md-8"><strong>Format:</strong> Zeitungsformat bis Postkartengröß, ohne Flappe, schlechte Papierqualität, ohne Tip-on-Card oder Warenprobe.</div>
      </div>
      <div class="row kategorie-box">
        <div class="col-lg-3 col-md-4">
          <span>Kategorie C</span>
        </div>
        <div class="col-lg-9 col-md-8"><strong>Format:</strong> Zeitungsformat bis Postkartengröße, mit Flappe oder aufgeklebter Tip-on­Card, schlechte Papierqualität, ohne Warenprobe.</div>
      </div>
      <ul class="content-ul">
        <li>Bei Sonderwerbeformen (Beihefter, aufgeklebte Warenprobe usw.) gilt eine Verringerung der Beilagengarantie um weitere 5 % als vereinbart.</li>
        <li>Für Beilagenkombinationen (selektive Beilagen), bei denen ein mehr­maliges Ein-/Ausschalten der verschiedenen Beilagen notwendig ist, verringert sich die Garantie je Position um mind. weitere 5 %. Zusätzlich kommt es durch den Beilagenwechsel zu Paketen mit gemischten Beilagen.</li>
        <li>Bei einer Kombination von verschiedenen Beilagen bestimmt die schlechteste und/oder die in der Kombinations- und Produktions­abfolge kleinste Auflage die Beilagengarantie.</li>
        <li>Reklamationen müssen innerhalb von sieben Tagen schriftlich vorliegen. Später eintreffende Reklamationen können nicht berücksichtigt werden.</li>
        <li>Aufträge werden mit der üblichen Korrektheit ausgeführt. Die Druckerei trägt keine Verantwortung für die während der Produktion verloren ge­gangenen Beilagen/Sonderwerbeformen. Es wird keine Kompensation gewährt, wenn mehrere gleiche Beilagen in einer Zeitung eingelegt sind.</li>
      </ul>
      <h2>Beschaffenheit Beilagen</h2>
      <div class="tabelle-box tabelle-box-v2">
        <div class="headline">
          Einzelblätter
        </div>
        <div class="row head-row hide-small">
          <div class="col-lg-4 text-align-left">Formatbereich</div>
          <div class="col-lg-4 text-align-left">Mindest-Flächengewicht</div>
          <div class="col-lg-4 text-align-left">Maximales Flächengewicht</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Formatbereich</div>
          <div class="col-lg-4 col-6 text-align-left">DIN A6 105 x 148 mm <br />DIN A6/ 5 105 x 210 mm</div>
          <div class="col-6 text-align-left visible-small">Mindest-Flächengewicht</div>
          <div class="col-lg-4 col-6 text-align-left">170 g/m<sup>2</sup></div>
          <div class="col-6 text-align-left visible-small">Maximales Flächengewicht</div>
          <div class="col-lg-4 col-6 text-align-left">200 g/m<sup>2</sup></div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Formatbereich</div>
          <div class="col-lg-4 col-6 text-align-left">DINA5148x210mm <br />{mind . 120x205 mm)</div>
          <div class="col-6 text-align-left visible-small">Mindest-Flächengewicht</div>
          <div class="col-lg-4 col-6 text-align-left">150 g/m<sup>2</sup></div>
          <div class="col-6 text-align-left visible-small">Maximales Flächengewicht</div>
          <div class="col-lg-4 col-6 text-align-left">200 g/m<sup>2</sup></div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Formatbereich</div>
          <div class="col-lg-4 col-6 text-align-left">DINA4210x297mm</div>
          <div class="col-6 text-align-left visible-small">Mindest-Flächengewicht</div>
          <div class="col-lg-4 col-6 text-align-left">135 g/m<sup>2</sup> Volumen­ papier 1.1</div>
          <div class="col-6 text-align-left visible-small">Maximales Flächengewicht</div>
          <div class="col-lg-4 col-6 text-align-left">200 g/m<sup>2</sup></div>
        </div>
      </div>
      <div class="tabelle-box tabelle-box-v2">
        <div class="headline">
          Mehrseitige Beilagen
        </div>
        <div class="row head-row hide-small">
          <div class="col-lg-3 text-align-left">Formatbereich</div>
          <div class="col-lg-3 text-align-left">Mindestseiten-Umfang</div>
          <div class="col-lg-3 text-align-left">Max. Seitenumfang</div>
          <div class="col-lg-3 text-align-left">Max. Gewicht je Exemplar</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Formatbereich</div>
          <div class="col-lg-3 col-6 text-align-left">DIN A6 105 x 148 mm <br />DIN A6/ 5 105 x 210 mm</div>
          <div class="col-6 text-align-left visible-small">Mindestseiten-Umfang</div>
          <div class="col-lg-3 col-6 text-align-left">4 Seiten (115 g/m<sup>2</sup>)</div>
          <div class="col-6 text-align-left visible-small">Max. Seitenumfang</div>
          <div class="col-lg-3 col-6 text-align-left">24 Seiten (80 g/m<sup>2</sup>)</div>
          <div class="col-6 text-align-left visible-small">Max. Gewicht je Exemplar</div>
          <div class="col-lg-3 col-6 text-align-left">16 g</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Formatbereich</div>
          <div class="col-lg-3 col-6 text-align-left">DIN A5 148x210mm <br />(mind . 120x205 mm)</div>
          <div class="col-6 text-align-left visible-small">Mindestseiten-Umfang</div>
          <div class="col-lg-3 col-6 text-align-left">4 Seiten (115 g/m<sup>2</sup>)</div>
          <div class="col-6 text-align-left visible-small">Max. Seitenumfang</div>
          <div class="col-lg-3 col-6 text-align-left">28 Seiten (80 g/m<sup>2</sup>)</div>
          <div class="col-6 text-align-left visible-small">Max. Gewicht je Exemplar</div>
          <div class="col-lg-3 col-6 text-align-left">46 g</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Formatbereich</div>
          <div class="col-lg-3 col-6 text-align-left">DIN AS Querformat</div>
          <div class="col-6 text-align-left visible-small">Mindestseiten-Umfang</div>
          <div class="col-lg-3 col-6 text-align-left">4 Seiten (115 g/m<sup>2</sup>)</div>
          <div class="col-6 text-align-left visible-small">Max. Seitenumfang</div>
          <div class="col-lg-3 col-6 text-align-left">56 Seiten (65 g/m<sup>2</sup>)</div>
          <div class="col-6 text-align-left visible-small">Max. Gewicht je Exemplar</div>
          <div class="col-lg-3 col-6 text-align-left">57 g</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Formatbereich</div>
          <div class="col-lg-3 col-6 text-align-left">Guide-Format (190x 190mm)<br />(max. 205 x 190 mm)</div>
          <div class="col-6 text-align-left visible-small">Mindestseiten-Umfang</div>
          <div class="col-lg-3 col-6 text-align-left">4 Seiten (115 g/m<sup>2</sup>)</div>
          <div class="col-6 text-align-left visible-small">Max. Seitenumfang</div>
          <div class="col-lg-3 col-6 text-align-left">bis max. 80 g</div>
          <div class="col-6 text-align-left visible-small">Max. Gewicht je Exemplar</div>
          <div class="col-lg-3 col-6 text-align-left">80 g</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Formatbereich</div>
          <div class="col-lg-3 col-6 text-align-left">DINA4210x297mm</div>
          <div class="col-6 text-align-left visible-small">Mindestseiten-Umfang</div>
          <div class="col-lg-3 col-6 text-align-left">4 Seiten (115 g/m<sup>2</sup>)</div>
          <div class="col-6 text-align-left visible-small">Max. Seitenumfang</div>
          <div class="col-lg-3 col-6 text-align-left">bis max. 120 g</div>
          <div class="col-6 text-align-left visible-small">Max. Gewicht je Exemplar</div>
          <div class="col-lg-3 col-6 text-align-left">120 g</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Formatbereich</div>
          <div class="col-lg-3 col-6 text-align-left">DIN A4 bis Maximalformat</div>
          <div class="col-6 text-align-left visible-small">Mindestseiten-Umfang</div>
          <div class="col-lg-3 col-6 text-align-left">4 Seiten (115 g/m<sup>2</sup>)</div>
          <div class="col-6 text-align-left visible-small">Max. Seitenumfang</div>
          <div class="col-lg-3 col-6 text-align-left">bis max. 120 g</div>
          <div class="col-6 text-align-left visible-small">Max. Gewicht je Exemplar</div>
          <div class="col-lg-3 col-6 text-align-left">120 g</div>
        </div>
      </div>
      <div class="tabelle-box tabelle-width-50 tabelle-box-v2">
        <div class="headline">
          Zeitungsvordrucke
        </div>
        <div class="row head-row hide-small">
          <div class="col-lg-4 text-align-left">Seitenumfang</div>
          <div class="col-lg-4 text-align-left">Mind. Papierstärke</div>
          <div class="col-lg-4 text-align-left">Verarbeitung</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Seitenumfang</div>
          <div class="col-lg-4 col-6 text-align-left">8 - 12 Seiten</div>
          <div class="col-6 text-align-left visible-small">Mind. Papierstärke</div>
          <div class="col-lg-4 col-6 text-align-left">55 g L-Printoder gleiches</div>
          <div class="col-6 text-align-left visible-small">Verarbeitung</div>
          <div class="col-lg-4 col-6 text-align-left">ungeheftet</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Seitenumfang</div>
          <div class="col-lg-4 col-6 text-align-left">16 - 96 Seiten</div>
          <div class="col-6 text-align-left visible-small">Mind. Papierstärke</div>
          <div class="col-lg-4 col-6 text-align-left">45 g Zeitungspapier</div>
          <div class="col-6 text-align-left visible-small">Verarbeitung</div>
          <div class="col-lg-4 col-6 text-align-left">geheftet</div>
        </div>
      </div>
      <h4>Format der Beilagen</h4>
      <ul class="content-ul">
        <li>Mindestformat DIN A6 (105 x 148 mm)</li>
        <li>Maximalformat 300 x 225 mm</li>
      </ul>
      <h4>Beschnittene Beilagen</h4>
      <ul class="content-ul">
        <li>Alle Beilagen müssen im rechten Winkel geschnitten sein.</li>
        <li>Sie dürfen keine durch mangelhafte Messer verursachten Schnittfehler aufweisen.</li>
        <li>Durch stumpfe Schneidmesser im Schnitt verblockte Beilagen können nicht verarbeitet werden.</li>
      </ul>
      <h4>Gewichte</h4>
      <ul class="content-ul">
        <li>Das Gewicht einer Beilage soll die genannten Höchstgrenzen nicht überschreiten. Liegt es darüber, ist eine Rückfrage im Expedit erforderlich.</li>
        <li>Auf keinen Fall darf das Gewicht der Beilage(n) jenes der Zeitung überschreiten.</li>
        <li>Ist eine Beilage schwerer als laut Standardgrammatur vorgesehen (120g) und soll diese zusätzlich mutiert werden, wird ein Schieberecht von +/- einem Tag eingeräumt.</li>
      </ul>
      <h4>Falzarten</h4>
      <ul class="content-ul">
        <li>Gefalzte Beilagen müssen im Einbruch bzw. Kreuzbruch oder Wickelfalz verarbeitet sein.</li>
        <li>Leporello- (Zickzack-) bzw. Altarfalz (auch Fensterfalz genannt) kann nur händisch verarbeitet werden.</li>
      </ul>
      <h4>Aufgeklebte Produkte</h4>
      <ul class="content-ul">
        <li>Postkarten sind in der Beilage grundsätzlich innen anzukleben. Sie müssen dabei bündig im Falz zum Kopf oder Fuß der Beilage angeklebt werden.</li>
        <li>Es sollte keine Punkt- sondern nur Strichleimung angewendet werden.</li>
        <li>Bei allen Beilagen mit außen angeklebten Produkten ist eine Abstimmung mit dem Expedit notwendig.</li>
        <li>Die maschinelle Verarbeitung von Beilagen mit Sonderformaten, Warenmustern oder Proben ist ohne vorherige technische Überprüfung durch das Expedit nicht möglich.</li>
      </ul>
      <h4>Ein- oder zweiseitige Flappen</h4>
      <ul class="content-ul">
        <li>Beilagen mit Flappen können erst nach Rücksprache und vorheriger technischer Prüfung durch das Expedit maschinell verarbeitet werden.</li>
      </ul>
      <h4>Kuverts</h4>
      <ul class="content-ul">
        <li>Bei Kuverts ist darauf zu achten, dass der Inhalt dieses ausfüllt. Sollte der Inhalt nicht das selbe Format wie das Kuvert haben, ist die Vorlage von Mustern und eine Rücksprache mit dem Expedit erforderlich. Das Gewicht des Kuverts darf 55 Gramm nicht überschreiten. Schwerere Kuverts können nur auf Anfrage bearbeitet werden.</li>
      </ul>
      <h4>Zahlscheine</h4>
      <ul class="content-ul">
        <li>Zahlscheine müssen mit Allonge produziert und auf ein Format von DIN A5 gefalzt werden.</li>
        <li>Die Perforation muss zwingend als Laser- bzw. Mikroperforation ausgeführt sein.</li>
      </ul>
      <h4>Draht-Rückenheftung bzw. Klebefalzung</h4>
      <ul class="content-ul">
        <li>Eine Draht-Rückenheftung sollte erst ab einem Seitenumfang von 16 Seiten verwendet werden. Dabei ist auf eine ordentliche und fehlerfreie Heftung zu achten. Bei geringerem Seitenumfang mit Heftung kann keine Garantie für die ordnungsgemäße Verar-beitung übernommen werden.</li>
        <li>Alle Beilagen sollten grundsätzlich mit Rücken- oder Falzleimung hergestellt werden.</li>
      </ul>
      <h2>Beschaffenheit Sonderwerbeformen</h2>
      <h4>Tip on Cards</h4>
      <ul class="content-ul">
        <li>Die maschinelle Verarbeitung ist nur mit Einzelkarten möglich. Die Verarbeitung erfolgt ausschließlich im Hochformat.</li>
        <li>Die kleinste zu belegende Einheit ist der politische Bezirk, der Stadtbezirk oder die PLZ.</li>
        <li>Papierformat A6 (105 x 148 mm): BD 200 g Volumen matt oder max. 250 g glänzend</li>
        <li>Papierlaufrichtung: Schmalbahn, längsseits zu 148 mm.</li>
        <li>Die Karten dürfen nicht lackiert sein, da sich diese dadurch verbiegen und daher nicht verarbeitet werden können.</li>
        <li>Klebebereich: Titelseite oder Rückseite der Zeitung.</li>
        <li>Mindestauflage: 50.000 Stück (Titelseite) bzw. 5000 Stück in einem ganzen Bezirk bzw. einer Sequenz (U4). Kleinere oder selektive Aufträge können nur händisch ausgeführt werden. Die anfallenden Mehrkosten werden weiterverrechnet.</li>
        <li>Die Definition von Vorder- bzw. Rückseite der Karte ist zwingend notwendig. Die Übermittlung einer PDF-Datei mit 3 mm Überfüller an allen Seiten und Schneidemarken wird empfohlen.</li>
      </ul>
      <h4>MemoStick</h4>
      <ul class="content-ul">
        <li>Standardformat: 76,2 x 76,2 mm</li>
        <li>Gutschein: 76,2 x 51 mm Trägerfläche des Memosticks: 76,2 x 76,2 mm</li>
        <li>
          Spezialformate:
          <ul>
            <li>Booklet 4 Seiten: 124 x 76,2 mm</li>
            <li>Booklet 6 Seiten: 224 x 76,2 mm</li>
            <li>Booklet 8 Seiten: 230 x 73 mm</li>
            <li>Memostick Medium: 76,2 x 101,6 mm</li>
            <li>Memostick Large: 76,2 x 152,4 mm</li>
            <li>Membership oder Magnetic-Card: 76 x 51 mm</li>
            <li>Warenproben oder gestanzte Formen auf Anfrage</li>
          </ul>
        </li>
        <li>Eine selektive Belegung einzelner Rayone oder Teilrouten ist nicht möglich.</li>
        <li>Die kleinste zu belegende Einheit ist der politische Bezirk, der Stadtbezirk oder die Postleitzahl.</li>
      </ul>
    </div>
    <div class="tabelle-box tabelle-box-v2">
      <div class="headline">
        Zeitungsumschlag (Mantel)
      </div>
      <div class="row head-row hide-small">
        <div class="col-lg-3 text-align-left">Seitenumfang</div>
        <div class="col-lg-3 text-align-left">Format</div>
        <div class="col-lg-3 text-align-left">Mind. Papierstärke</div>
        <div class="col-lg-3 text-align-left">Laufrichtung</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Seitenumfang</div>
        <div class="col-lg-3 col-6 text-align-left">4-16 Seiten</div>
        <div class="col-6 text-align-left visible-small">Format</div>
        <div class="col-lg-3 col-6 text-align-left">235 + 225 x 300 mm mit Vorfalz</div>
        <div class="col-6 text-align-left visible-small">Mind. Papierstärke</div>
        <div class="col-lg-3 col-6 text-align-left">135 g/m<sup>2</sup> Volumenpapier, 1.1 nicht lackiert!</div>
        <div class="col-6 text-align-left visible-small">Laufrichtung</div>
        <div class="col-lg-3 col-6 text-align-left">Laufrichtung = Längsrichtung des offenen Produktes</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Seitenumfang</div>
        <div class="col-lg-3 col-6 text-align-left">20-48 Seiten</div>
        <div class="col-6 text-align-left visible-small">Format</div>
        <div class="col-lg-3 col-6 text-align-left">235 + 225 x 300 mm mit Vorfalz</div>
        <div class="col-6 text-align-left visible-small">Mind. Papierstärke</div>
        <div class="col-lg-3 col-6 text-align-left">80 g/m<sup>2</sup></div>
        <div class="col-6 text-align-left visible-small">Laufrichtung</div>
        <div class="col-lg-3 col-6 text-align-left">Laufrichtung = Längsrichtung des offenen Produktes</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Seitenumfang</div>
        <div class="col-lg-3 col-6 text-align-left">4 Seiten Transparentumschlag</div>
        <div class="col-6 text-align-left visible-small">Format</div>
        <div class="col-lg-3 col-6 text-align-left">235 + 225 x 300 mm mit Vorfalz</div>
        <div class="col-6 text-align-left visible-small">Mind. Papierstärke</div>
        <div class="col-lg-3 col-6 text-align-left">Transparentpapier 105 g/m<sup>2</sup>, nicht lackiert!</div>
        <div class="col-6 text-align-left visible-small">Laufrichtung</div>
        <div class="col-lg-3 col-6 text-align-left">Laufrichtung = Längsrichtung des offenen Produktes</div>
      </div>
    </div>
    <div class="download-pdf">
      <div class="line"><span>PDF</span></div>
      <a href="../../pdf/beilagen/Spezifikationen-Beilagen-SWF-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Spezifikation Beilagen
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>269 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';

export default defineComponent({
  name: 'Spezifikationen',
  components: {
    //Modal,
  },
});
</script>
